import axios, { AxiosPromise, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios"
import config from "./config"

declare module "axios" {
  interface AxiosInstance {
    handleError: (error: any) => void
    postWithCancel: (url: string, payload: Object) => AxiosPromise<any>
    getWithCancel: (url: string, options?: AxiosRequestConfig) => AxiosPromise<any>
  }
}

// keep track of abort controllers by request METHOD:URL
const AbortControllerMap = new Map<string, AbortController>()

const abortAndRemove = (method: "POST" | "GET", url: string) => {
  const abortKey = getAbortKey(method, url)
  if (AbortControllerMap.has(abortKey)) {
    const ctrl = AbortControllerMap.get(abortKey)
    ctrl?.abort()
    AbortControllerMap.delete(abortKey)
  }
}

const addAbort = (method: "POST" | "GET", url: string) => {
  const ctrl = new AbortController()
  AbortControllerMap.set(getAbortKey(method, url), ctrl)
  return ctrl
}

const getAbortKey = (method: "POST" | "GET", url: string) => `${method}:${url}`

const customAxios = axios.create({
  withCredentials: true,
  baseURL: config.BASE_URL,
  headers: {
    Accept: "application/json",
  },
})

customAxios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const csfr = document.querySelector<HTMLMetaElement>("meta[name=csrf-token]")?.content
  const query = new URLSearchParams(window.location.search)
  const locale = query.get("locale")

  if (csfr) {
    config.headers.set("X-CSRF-Token", csfr)
  }
  if (locale) {
    config.headers.set("Locale", locale)
  }
  return config
})

// FIXME: this is a global error handler, it should not have application specific code like App.alert
// this should be api client level error handling
customAxios.handleError = error => {
  if (error.response && error.response.data) {
    const msg = error.response.data.errors
      ? error.response.data.errors.join(", ")
      : "Etwas ist leider schief gelaufen. Bitte probiere es später erneut."
    App.alert(msg)
  } else if (error.error_messages) {
    App.alert(error.error_messages.join(", "))
  } else {
    console.error(error)
  }
}

type AxiosWithAbortRequestConfig = AxiosRequestConfig & { abortKey?: string }

customAxios.postWithCancel = (url: string, payload: any, options: AxiosWithAbortRequestConfig = {}) => {
  const abortKey = options.abortKey ?? url
  abortAndRemove("POST", abortKey)
  const ctrl = addAbort("POST", abortKey)
  return customAxios.post(url, payload, { signal: ctrl.signal, ...options })
}

customAxios.getWithCancel = (url: string, options: AxiosWithAbortRequestConfig = {}) => {
  const abortKey = options.abortKey ?? url
  abortAndRemove("GET", abortKey)
  const ctrl = addAbort("GET", abortKey)
  return customAxios.get(url, { ...options, signal: ctrl.signal })
}

export default customAxios
